import React from "react";

import "../styles/components/RequestPaymentNotFound.scss";

export default function RequestPaymentWarning() {
  return (
    <div className="request-payment-not-found">
      <div className="ui yellow small message">
        <div className="header">
          Pedido ainda esta sendo processado e o pagamento estará disponível
          para ser feito em breve.
        </div>
        <p>
          Deseja pesquisar uma nova requisição ? <a href="/">Clique aqui</a>
        </p>
      </div>
    </div>
  );
}
