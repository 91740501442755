import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InputMask from "react-input-mask";
import Cards from "react-credit-cards";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import "../styles/pages/MainPayment.scss";
import "react-credit-cards/lib/styles.scss";
import { GetCardBrand } from "../commons/Cards";
import IonCardIcon from "../assets/images/logo_ion_pay.png";
import IonCardBack from "../assets/images/arrow_back_white.svg";
import RequestPaymentNotFound from "../components/RequestPaymentNotFound";
import RequestPaymentDetail from "../components/RequestPaymentDetail";
import PositiveMessage from "../components/PositiveMessage";
import NegativeMessage from "../components/NegativeMessage";
import EmptyFieldsMessage from "../components/EmptyFieldsMessage";
import RequestPaymentCanceled from "../components/RequestPaymentCanceled";
import LoadingSpiner from "../assets/images/loading.gif";
import {
  getRequestPayment,
  postRequestpayment,
} from "../services/paymentRequestService";
import { getCep } from "../services/cepService";
import { postAuthorizationClient } from "../services/authorizationService";
import { Button, Form, Typography } from "antd";
import { DigitalWallet } from "../components/DigitalWallet";
import "antd/dist/antd.css";
import { Loading } from "../components/Loading";
import RequestPaymentWarning from "../components/RequestPaymentWarning";

const MainPayment = () => {
  const [cvc, setCvc] = useState("");
  const [expiry, setExpiry] = useState("");
  const [focus, setFocus] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [identity, setIdentity] = useState("");
  const [identityType, setIdentityType] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [cep, setCep] = useState("");
  const [log, setLog] = useState("");
  const [num, setNum] = useState("");
  const [comp, setComp] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");

  const [requestIsLoading, setRequestIsLoading] = useState(false);
  const [requestPaymentPaidOut, setRequestPaymentPaidOut] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [requestPaymentNotFound, setRequestPaymentNotFound] = useState(false);
  const [requestPaymentWarning, setRequestPaymentWarning] = useState(false);
  const [typeCard, setTypeCard] = useState(false);
  const [requestPaymentDetail, setResquestPaymentDetail] = useState({});
  const [showNegativeMessage, setShowNegativeMessage] = useState(false);
  const [showPositiveMessage, setShowPositiveMessage] = useState(false);
  const [showEmptyFieldsMessage, setShowEmptyFieldsMessage] = useState(false);
  const [requestPaymentCanceled, setRequestPaymentCanceled] = useState(false);
  const [isDigital, setIsDigital] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [dataToken, setDataToken] = useState(undefined);
  const [statusRequestPayment, setStatusRequestPayment] = useState(undefined);
  const [requiresBearerData, setRequiresBearerData] = useState(false);
  const { Text } = Typography;
  const [isLoading, setIsLoading] = useState(false);
  const [showAddressView, setShowAddressView] = useState(false);
  let { id } = useParams();
  const [form] = Form.useForm();

  // eslint-disable-next-line
  useEffect(() => {
    const fetchData = async () => {
      setRequestIsLoading(true);
      const responseToken = await postAuthorizationClient();

      if (responseToken?.access_token) {
        setDataToken(responseToken);
        const response = await getRequestPayment(id);

        if (response?.status === 200) {
          if (response?.data?.paymentRequestStatus === "CANCELADO") {
            setResquestPaymentDetail(response?.data);
            setRequestPaymentCanceled(true);
          } else if (response?.data?.paymentRequestStatus === "FINALIZADO") {
            setRequestPaymentPaidOut(true);
            setResquestPaymentDetail(response?.data);
          } else if (
            response?.data?.paymentRequestStatus === "PENDENTE_INICIALIZACAO" &&
            response?.data?.serviceType === "CREDIT_CARD"
          ) {
            setRequestPaymentWarning(true);
          }

          if (response?.data?.serviceType === "DIGITAL") {
            setStatusRequestPayment(response?.data);
            setIsDigital(true);
          } else {
            setRequiresBearerData(
              response?.data?.requerValidacaoDadosDoPortador
            );
            setIsDigital(false);
            setResquestPaymentDetail(response?.data);
          }
          setRequestIsLoading(false);
        } else {
          setRequestIsLoading(false);
          setRequestPaymentNotFound(true);
        }
      } else {
        setRequestIsLoading(false);
        setAlertMessage(`error`);
        setRequestPaymentNotFound(true);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    handleButtonEnabled();
    // eslint-disable-next-line
  }, [
    cvc,
    expiry,
    name,
    number,
    firstName,
    lastName,
    identity,
    identityType,
    phone,
    email,
    cep,
    log,
    num,
    comp,
    district,
    city,
    state,
    country,
  ]);

  const handleGetCep = async (cep) => {
    setIsLoading(true);
    clearAddressFields();
    const result = await getCep(cep);
    setCep(cep);

    if (result.status === 200) {
      setLog(result?.data?.logradouro);
      setComp(result?.data?.complemento);
      setDistrict(result?.data?.bairro);
      setCity(result?.data?.localidade);
      setState(result?.data?.uf);

      form.setFieldsValue({
        setLog: result?.data?.logradouro,
        setComp: result?.data?.complemento,
        setDistrict: result?.data?.bairro,
        setCity: result?.data?.localidade,
        setState: result?.data?.uf,
      });
    }
    setIsLoading(false);
    setShowAddressView(true);
    window.scrollBy({ top: 200, behavior: "smooth" });
  };

  const clearAddressFields = () => {
    form.resetFields([
      "setLog",
      "setNum",
      "setComp",
      "setDistrict",
      "setCity",
      "setState",
      "setCountry",
    ]);
  };

  const handleButtonEnabled = () => {
    if (requiresBearerData) {
      setButtonEnabled(
        cvc?.length > 0 &&
          expiry.length > 0 &&
          name?.length > 0 &&
          number.length > 0 &&
          firstName?.length > 0 &&
          lastName?.length > 0 &&
          identity?.length >= 14 &&
          identityType?.length > 0 &&
          phone?.length > 0 &&
          email?.length > 0 &&
          cep?.length === 8 &&
          log?.length > 0 &&
          num?.length > 0 &&
          district?.length > 0 &&
          city?.length > 0 &&
          state?.length > 0 &&
          country?.length > 0
      );
    } else {
      setButtonEnabled(
        cvc.length > 0 &&
          expiry.length > 0 &&
          name.length > 0 &&
          number.length > 0
      );
    }
  };

  const handleSubmitPayment = async () => {
    let brand = GetCardBrand(number).toUpperCase();

    const body = {
      cardHolder: name,
      cardNumber: number.replaceAll(" ", "").replaceAll("_", ""),
      expirationDate: expiry,
      securityCode: cvc,
      brand: brand,
      dadosPortador: {
        nome: `${firstName} ${lastName}`,
        primeiroNome: firstName,
        ultimoNome: lastName,
        email: email,
        tipoDocumento: identityType,
        numeroDocumento: identity.replaceAll(".", "").replaceAll("-", ""),
        numeroTelefone: phone,
        enderecoCobranca: {
          endereco: log,
          numero: num,
          complemento: comp,
          bairro: district,
          cidade: city,
          estado: state,
          cep: cep.replaceAll("-", ""),
          pais: country,
        },
      },
    };

    setRequestIsLoading(true);
    const response = await postRequestpayment(id, body);

    if (response?.status === 200) {
      handleClearShows();
      setShowPositiveMessage(true);
    } else {
      setAlertMessage(`${response?.userMessage} código ${response?.errorCode}`);
      setShowEmptyFieldsMessage(true);
    }
    setRequestIsLoading(false);
  };

  const handleClearShows = () => {
    setShowEmptyFieldsMessage(false);
    setShowNegativeMessage(false);
  };

  const closeEmptyFieldsMessage = () => {
    setShowEmptyFieldsMessage(false);
    setAlertMessage(null);
  };

  const closeNegativeMessage = () => {
    setShowNegativeMessage(false);
  };

  const handleNumberCard = (value) => {
    setNumber(value);
    let brand = GetCardBrand(number).toUpperCase();
    if (brand === "AMEX") {
      setTypeCard(true);
    } else {
      setTypeCard(false);
    }
  };

  const onFinish = () => {
    handleSubmitPayment();
  };

  const onFinishFailed = (errorInfo) => {
    let array = [];
    Object.keys(errorInfo?.values).forEach((item) => {
      if (errorInfo?.values[item] === undefined) {
        array.push(`${getName(item)}`);
      }
    });
    let fields = `Por favor, preencha os campos: ${array.join(",")}`;

    setAlertMessage(fields);
    setShowEmptyFieldsMessage(true);
  };

  const getName = (item) => {
    switch (item) {
      case "setFirstName":
        return "primeiro nome";

      case "setLastName ":
        return "ultimo nome";

      case "identity":
        return "tipo de documento";

      case "setPhone":
        return "telefone";

      case "setEmail":
        return "e-mail";

      case "setCep":
        return "cep";

      case "setLog":
        return "rua";
      case "setNum":
        return "número";

      case "setComp":
        return "complemento";

      case "setDistrict":
        return "bairro";

      case "setCity":
        return "cidade";

      case "setState":
        return "estado";

      case "setCountry":
        return "país";

      default:
        break;
    }
  };

  const handlePhoneChange = (e) => {
    const phoneValue = e.target.value;

    const value = phoneValue
      .replaceAll("_", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll("-", "");
    if (value?.length === 11) {
      setPhone(value);
    }
  };

  const bearerDataView = () => {
    return (
      <>
        <Form.Item
          style={{ marginBottom: "20px" }}
          name="setFirstName"
          rules={[
            { required: true, message: "Insira o primeiro nome do titular" },
          ]}
        >
          <input
            type="text"
            placeholder="Primeiro Nome"
            className="creditCard-m-button"
            onChange={(e) => setFirstName(e.target.value)}
            onFocus={(e) => setFocus(e.target.name)}
            autoComplete="off"
            size="50"
          />
        </Form.Item>

        <Form.Item
          style={{ marginBottom: "20px" }}
          name="setLastName"
          rules={[
            { required: true, message: "Insira o ultimo nome do titular" },
          ]}
        >
          <input
            type="text"
            placeholder="Ultimo nome"
            className="creditCard-m-button"
            onChange={(e) => setLastName(e.target.value)}
            onFocus={(e) => setFocus(e.target.name)}
            autoComplete="off"
            size="50"
          />
        </Form.Item>

        <Form.Item
          style={{ marginBottom: "20px" }}
          name="identity"
          rules={[{ required: true, message: "Insira CPF/CNPJ do titular" }]}
        >
          <CpfCnpj
            value={identity}
            onChange={(event, type) => {
              setIdentity(event.target.value);
              setIdentityType(type);
            }}
            onFocus={() => setFocus(number)}
            placeholder="CPF/CNPJ do Titular"
            className="styleIdentity"
          />
        </Form.Item>

        <Form.Item
          style={{ marginBottom: "20px" }}
          name="setPhone"
          rules={[{ required: true, message: "Insira o celular" }]}
        >
          <InputMask
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            mask={"(99)99999-9999"}
            placeholder="Insira o número de celular"
            className="creditCard-m-button"
            onChange={handlePhoneChange}
            autoComplete="off"
            size="50"
          />
        </Form.Item>

        <Form.Item
          style={{ marginBottom: "20px" }}
          name="setEmail"
          rules={[{ required: true, message: "Insira e-mail" }]}
        >
          <input
            type="text"
            placeholder="E-mail"
            className="creditCard-m-button"
            onChange={(e) => setEmail(e.target.value)}
            onFocus={(e) => setFocus(e.target.name)}
            autoComplete="off"
            size="50"
          />
        </Form.Item>
      </>
    );
  };

  const handleCepChange = async (e) => {
    const cepValue = e.target.value;

    const value = cepValue.replaceAll("_", "").replaceAll("-", "");
    if (value?.length === 8) {
      await handleGetCep(value);
    }
  };

  const addressView = () => {
    return (
      <Loading loading={isLoading}>
        <Form.Item
          style={{ marginBottom: "20px" }}
          name="setCep"
          rules={[{ required: true, message: "Informe o CEP" }]}
        >
          <InputMask
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            mask={"99999-999"}
            placeholder="Informe o CEP"
            className="creditCard-m-button"
            onChange={handleCepChange}
            autoComplete="off"
            size="50"
          />
        </Form.Item>

        {showAddressView && (
          <>
            <Form.Item
              style={{ marginBottom: "20px" }}
              name="setLog"
              rules={[{ required: true, message: "Informe o logradouro" }]}
            >
              <input
                type="text"
                placeholder="Logradouro"
                className="creditCard-m-button"
                onChange={(e) => setLog(e.target.value)}
                onFocus={(e) => setFocus(e.target.name)}
                autoComplete="off"
                size="50"
              />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "20px" }}
              name="setNum"
              rules={[{ required: true, message: "Informe o número" }]}
            >
              <input
                type="text"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder="Número"
                className="creditCard-m-button"
                onChange={(e) => setNum(e.target.value)}
                onFocus={(e) => setFocus(e.target.name)}
                autoComplete="off"
                size="50"
              />
            </Form.Item>

            <Form.Item style={{ marginBottom: "20px" }} name="setComp">
              <input
                type="text"
                placeholder="Complemento"
                className="creditCard-m-button"
                onChange={(e) => setComp(e.target.value)}
                onFocus={(e) => setFocus(e.target.name)}
                autoComplete="off"
                size="50"
              />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "20px" }}
              name="setDistrict"
              rules={[{ required: true, message: "Informe o bairro" }]}
            >
              <input
                type="text"
                placeholder="Bairro"
                className="creditCard-m-button"
                onChange={(e) => setDistrict(e.target.value)}
                onFocus={(e) => setFocus(e.target.name)}
                autoComplete="off"
                size="50"
              />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "20px" }}
              name="setCity"
              rules={[{ required: true, message: "Informe a cidade" }]}
            >
              <input
                type="text"
                placeholder="Cidade"
                className="creditCard-m-button"
                onChange={(e) => setCity(e.target.value)}
                onFocus={(e) => setFocus(e.target.name)}
                autoComplete="off"
                size="50"
              />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "20px" }}
              name="setState"
              rules={[{ required: true, message: "Informe o estado" }]}
            >
              <input
                type="text"
                placeholder="Estado"
                className="creditCard-m-button"
                onChange={(e) => setState(e.target.value)}
                onFocus={(e) => setFocus(e.target.name)}
                autoComplete="off"
                size="50"
              />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "20px" }}
              name="setCountry"
              rules={[{ required: true, message: "Informe o país" }]}
            >
              <input
                type="text"
                placeholder="País"
                className="creditCard-m-button"
                onChange={(e) => setCountry(e.target.value)}
                onFocus={(e) => setFocus(e.target.name)}
                autoComplete="off"
                size="50"
              />
            </Form.Item>
          </>
        )}
      </Loading>
    );
  };

  const viewForm = () => {
    return (
      <div className={"containerForm"}>
        <div className={"contentForm"}>
          <Form
            form={form}
            name="basic"
            style={{ maxWidth: 600 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              style={{ marginBottom: "20px" }}
              name="number"
              rules={[{ required: true, message: "Insira número do cartão" }]}
            >
              <InputMask
                mask={typeCard ? "9999 999999 99999" : "9999 9999 9999 9999"}
                type="tel"
                placeholder="Número do cartão"
                className="creditCard-m-button"
                onChange={(e) => handleNumberCard(e.target.value)}
                onFocus={(e) => setFocus(e.target.name)}
              />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "20px" }}
              name="name"
              rules={[
                { required: true, message: "Insira nome completo do titular" },
              ]}
            >
              <input
                type="text"
                placeholder="Nome impresso no cartão"
                className="creditCard-m-button"
                onChange={(e) => setName(e.target.value)}
                onFocus={(e) => setFocus(e.target.name)}
                autoComplete="off"
                size="50"
              />
            </Form.Item>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                style={{ marginBottom: "20px", width: "60%" }}
                name="expiry"
                rules={[
                  { required: true, message: "Insira validade do cartao" },
                ]}
              >
                <InputMask
                  mask="99/9999"
                  type="tel"
                  placeholder="Validade"
                  className="left creditCard-m-button"
                  onChange={(e) => setExpiry(e.target.value)}
                  onFocus={(e) => setFocus(e.target.name)}
                />
              </Form.Item>

              <Form.Item
                style={{ marginBottom: "20px", width: "35%" }}
                name="cvc"
                rules={[
                  {
                    required: true,
                    message: "Insira o código de segurança do cartão",
                  },
                ]}
              >
                <InputMask
                  type="tel"
                  className="creditCard-m-button"
                  placeholder="CVC"
                  onChange={(e) => setCvc(e.target.value)}
                  onFocus={(e) => setFocus(e.target.name)}
                  mask={typeCard ? "9999" : "999"}
                />
              </Form.Item>
            </div>

            <>
              <div className={"title"}>
                <Text strong>Dados do Portador</Text>
              </div>

              {bearerDataView()}

              <div className={"title"}>
                <Text strong>Endereço de Cobrança</Text>
              </div>

              {addressView()}
            </>

            <Button
              htmlType="submit"
              disabled={buttonEnabled ? false : true}
              className={
                buttonEnabled
                  ? "button-payment spaceBottom"
                  : "button-payment-disabled spaceBottom"
              }
            >
              Pagar
            </Button>
          </Form>
        </div>
      </div>
    );
  };

  return (
    <>
      <div id="particles-js" className="bg-gradient">
        <canvas
          className="particles-js-canvas-el"
          style={{ width: "100%", height: "100%" }}
        ></canvas>
      </div>

      <div className="container-login">
        <div className="container">
          <div className="row justify-content-md-center mt-5">
            <div className="col-lg-12">
              <div>
                <img
                  src={IonCardIcon}
                  alt="logo icon"
                  className="card-logo-icon text-left"
                />
              </div>
              <div className="containerName">
                <span className="contentPname">ION</span>
                <span className="contentSname">PAY</span>
              </div>
              <div>
                <a href="/">
                  <img
                    src={IonCardBack}
                    alt="Voltar"
                    className="arrow_back_black icon float-left mb-2"
                    title="Voltar para a home"
                  />
                </a>
              </div>

              {requestIsLoading ? (
                <div className="loading-spiner">
                  <img src={LoadingSpiner} alt="loading..." />
                </div>
              ) : (
                <div>
                  {!requestPaymentNotFound &&
                    !isDigital &&
                    !requestPaymentWarning && (
                      <RequestPaymentDetail
                        requestPaymentDetail={requestPaymentDetail}
                      />
                    )}
                  {showEmptyFieldsMessage && (
                    <div className="fieldsMessage">
                      <EmptyFieldsMessage
                        closeEmptyMessage={closeEmptyFieldsMessage}
                        text={alertMessage}
                      />
                    </div>
                  )}
                  {requestPaymentWarning && <RequestPaymentWarning />}
                  {requestPaymentNotFound ? (
                    <RequestPaymentNotFound />
                  ) : requestPaymentPaidOut ? (
                    <PositiveMessage
                      headerMessage="Requisição de pagamento já concluída"
                      footerMessage="Deseja realizar o pagamento de uma nova requisição ?"
                      href="/"
                    />
                  ) : requestPaymentCanceled ? (
                    <RequestPaymentCanceled />
                  ) : showPositiveMessage ? (
                    <PositiveMessage
                      headerMessage="Pagamento realizado com sucesso"
                      footerMessage="Deseja realizar o pagamento de uma nova requisição ?"
                      href="/"
                    />
                  ) : isDigital ? (
                    <DigitalWallet
                      requestId={id}
                      data={dataToken}
                      statusRequestPayment={statusRequestPayment}
                    />
                  ) : (
                    !requestPaymentWarning && (
                      <div id="PaymentForm">
                        <div>
                          <div className="spaceBottom">
                            <Cards
                              cvc={cvc}
                              expiry={expiry}
                              focused={focus}
                              name={name}
                              number={number}
                            />
                          </div>

                          {viewForm()}
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}

              {showNegativeMessage && (
                <NegativeMessage closeNegativeMessage={closeNegativeMessage} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainPayment;
