import React from "react";
import moment from "moment";
import ShoppingCartIcon from "../assets/images/shopping-cart.svg";
import BusinessIcon from "../assets/images/business.svg";
import PaymentsIcon from "../assets/images/payments.svg";
import MonetizationOnIcon from "../assets/images/monetization-on.svg";

import "../styles/components/RequestPaymentDetail.scss";

export default function RequestPaymentDetail({ requestPaymentDetail }) {
  const formatPrice = (price) => {
    if (price) {
      return price?.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    }
    return "--";
  };

  const formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  return (
    <div className="container-request-payment">
      <div className="information">
        <div>
          <img
            src={ShoppingCartIcon}
            className="mini-icon black-to-purple"
            alt="icon"
          />
          Identificador do pagamento:{" "}
          <strong>{requestPaymentDetail.paymentRequestId}</strong>
        </div>

        <div>
          <span className="date">
            {formatDate(requestPaymentDetail.paymentRequestDate)}
          </span>
        </div>
      </div>

      <div className="business">
        <img
          src={BusinessIcon}
          className="mini-icon black-to-purple"
          alt="icon"
        />
        <span>{requestPaymentDetail.razaoSocial}</span>
      </div>

      <div className="information">
        <div>
          <img
            src={PaymentsIcon}
            className="mini-icon black-to-purple"
            alt="icon"
          />
          <span>Parcelas:</span>
        </div>

        <div>
          <strong>
            {`${requestPaymentDetail?.numParcelas} x ${formatPrice(
              requestPaymentDetail?.valorPedido /
                requestPaymentDetail?.numParcelas
            )}`}
          </strong>
        </div>
      </div>

      <div className="information-total">
        <div>
          <img
            src={MonetizationOnIcon}
            className="mini-icon black-to-purple"
            alt="icon"
          />
          <span>Valor total: </span>
        </div>

        <div>
          <strong>{formatPrice(requestPaymentDetail.valorPedido)}</strong>
        </div>
      </div>
    </div>
  );
}
